/**
 * Carousel
 */

/* Slideshow controls */

.carousel-arrow {
  position: absolute;
  top: 50%; left: 0; margin-top: -25px;
  display: block;
  width: 50px; height: 50px;
  text-align: center;
  border: 1px solid fade(black,12%);
  border-left-width: 0;
  border-radius: 0 @border-radius-base @border-radius-base 0;
  background-color: fade(white,70%);
  color: fade(black,70%);
  font-size: 28px;
  transition: all .1s linear;

  > .fa {
    line-height: 50px;
  }
  &:hover {
    padding-right: 5px;
  }
  &:hover, &:focus {
    color: fade(black,70%);
  }
}
.carousel-arrow-next {
  left: auto; right: 0;
  border-left-width: 1px;
  border-right-width: 0;
  border-radius: @border-radius-base 0 0 @border-radius-base;

  &:hover {
    padding-right: 0;
    padding-left: 5px;
  }
}
.carousel-arrow {
  opacity: 0;
}
.carousel:hover .carousel-arrow {
  opacity: 1;
}

/* Carousel indicators: Inverse */

.carousel-indicators_inverse > li {
  border-color: #ccc;

  &.active {
    width: 10px; height: 10px;
    margin: 0;
    border-color: #ccc;
    background-color: #ccc;
  }
}