/**
 * Affix
 */

@media(max-width: @screen-xs-max) {
  .affix {
    position: static !important;
    top: auto !important;
  }
}


/**
 * Blackout
 */

.blackout {
  position: relative;
}
.blackout__layer {
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-color: black;
  opacity: 0;
}


/**
 * Background parallax
 */

.bg-parallax,
.bg-parallax__layer {
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}
.bg-parallax {
  position: relative !important;
  overflow: hidden !important;
  background-color: transparent !important;
}
.bg-parallax__layer {
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  min-height: 100vh;
  z-index: -1;
}


/**
 * Categories
 */

.categories-list {
  list-style: none;
  padding-left: 5px;
  margin-bottom: 20px;

  > li {
    padding: 4px 0;
  }
}


/**
 * Rating
 */

.rating {
  display: inline-block;
  list-style: none;
  padding-left: 0;
  margin-bottom: 20px;

  li {
    display: inline-block;
    margin: 0;
    padding: 0;
    font-size: 16px;
    color: #F7C41F;
    cursor: pointer;
  }
}


/**
 * Features list
 */

.ft-list {
  list-style-type: none;
  padding-left: 0;

  > li {
    padding: 3px 0;
  }
  > li:before {
    content: "\f00c";
    font-family: FontAwesome;
    display: inline-block;
    margin-right: 10px;
    color: @brand-sec;
  }
}


/* Animation delay */

.delay-1 {
  .animation-delay(300ms);
}
.delay-2 {
  .animation-delay(600ms);
}
.delay-3 {
  .animation-delay(900ms);
}
.delay-4 {
  .animation-delay(1200ms);
}
.delay-5 {
  .animation-delay(1500ms);
}
.delay-6 {
  .animation-delay(1800ms);
}
.delay-7 {
  .animation-delay(2100ms);
}
.delay-8 {
  .animation-delay(2400ms);
}


/* Circle text block */

.circle(@diameter) {
  height: @diameter;
  width: @diameter;
  border-radius: @diameter;
  line-height: @diameter;
  text-align: center;
}
.block(@size; @border-width: 0) {
  width: @size; height: @size;
  line-height: @size - @border-width*2;
  text-align: center;
}


/* Lists */

.list-style-none {
  list-style: none;
  padding-left: 0;
}


/* Shadow effect */

.shadow-effect {
  position: relative;
  background: white;

  &:before, &:after {
    z-index: -1;
    position: absolute;
    content: "";
    bottom: 15px;
    left: 10px;
    width: 50%;
    top: 80%;
    max-width:300px;
    background: #777;
    .box-shadow(0 15px 10px #777);
    .rotate(-3deg);
  }
  &:after {
    .rotate(3deg);
    right: 10px;
    left: auto;
  }
}


/* Vertically centered columns */

  
@media(min-width: @screen-sm-min) {

  .row_v-centered {
    display: table;
    table-layout: fixed;
    width: 100%; height: 100%;

    [class*="col-"] {
      display: table-cell;
      vertical-align: middle;
      float: none;
      height: 100%;
    }
  }

}